import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import styles from "../style/component.module.css";

export default function BaseMultiSelect({
    // Items
    items,
    itemIdKey,
    selectedItems,
    setSelectedItems,

    // Selected items
    selectedItemsTitle,
    selectedItemsNoneText,
    selectedItemsShown,
    selectedItemRender,

    // Available items
    availableItemsTitle,
    availableItemsNoneText,
    availableItemsShown,
    availableItemRender,

    // Other
    selectAllText,
    disabled
}: {
        // Items
        items?: any[] | undefined,
        itemIdKey?: string | undefined,
        selectedItems: any[],
        setSelectedItems: React.Dispatch<React.SetStateAction<any[]>>,

        // Selected items
        selectedItemsTitle?: string | undefined,
        selectedItemsNoneText?: string | undefined,
        selectedItemsShown?: boolean | undefined,
        selectedItemRender?(selectedItem: any): React.ReactNode | undefined,

        // Available items
        availableItemsTitle?: string | undefined,
        availableItemsNoneText?: string | undefined,
        availableItemsShown?: boolean | undefined,
        availableItemRender?(availableItem: any): React.ReactNode | undefined,

        // Other
        selectAllText?: string | undefined,
        disabled?: boolean | undefined
        
}) {
    const handleItemSelect = (item: any, checked: boolean) => {
        const updateSelectedItems = [...selectedItems];
        const itemIndex = updateSelectedItems.findIndex(_ => _[itemIdKey ?? ""] === item[itemIdKey ?? ""])
        if (checked === true && itemIndex === -1) {
            updateSelectedItems.push(item);
        } else if (checked === false && itemIndex !== -1) {
            updateSelectedItems.splice(itemIndex, 1);
        }

        setSelectedItems(updateSelectedItems);
    }

    const handleSelectAllItems = () => {
        let updateSelectedItems = [ ...selectedItems ]
        if (selectAllChecked() === false) {
            const missingItems = items?.filter(i => selectedItems.find(si => i[itemIdKey ?? ""] === si[itemIdKey ?? ""]) === undefined)
            updateSelectedItems = updateSelectedItems.concat(missingItems);
        } else {
            updateSelectedItems = (updateSelectedItems ?? []).filter(i => (items ?? []).find(si => i[itemIdKey ?? ""] === si[itemIdKey ?? ""]) === undefined);
        }

        setSelectedItems(updateSelectedItems);
    }

    const selectAllChecked = () => {
        return (items ?? []).length > 0 &&
            selectedItems.length > 0 &&
            selectedItems.filter(s => (items ?? []).find(i => i[itemIdKey ?? ""] === s[itemIdKey ?? ""]) !== undefined).length === (items ?? []).length
    }

    return (
        <div>
            {selectedItemsShown !== false && <div className={styles.pb} >
                <div>
                    <h4>{selectedItemsTitle ?? 'Selected'}</h4>
                    {selectedItems.length === 0 && <p><i>{selectedItemsNoneText ?? 'Nothing selected'}</i></p>}
                    {selectedItemRender === undefined && <p><i>Selected item render is not defined</i></p>}
                    {selectedItemRender !== undefined && selectedItems.map(selectedItem => selectedItemRender(selectedItem))}
                </div>
            </div>}
            {availableItemsShown !== false && <div>
                <h4>{availableItemsTitle ?? 'Available'}</h4>
                {(items ?? []).length === 0 && <p><i>{availableItemsNoneText ?? 'Nothing available'}</i></p>}
                {availableItemRender === undefined && <p><i>Available item render is not defined</i></p>}
                {availableItemRender !== undefined && (items ?? []).length > 0 && <div>
                    <FormGroup
                        id={`select-all-form-group`}
                        className={styles.selectOption}
                    >
                        <FormControlLabel
                            id={`select-all-form-control-label`}
                            control={
                                <Checkbox
                                    id={`select-all-checkbox`}
                                    checked={selectAllChecked()}
                                    disabled={disabled}
                                    onChange={() => handleSelectAllItems()}
                                />
                            } label={selectAllText ?? 'Select all'} />
                    </FormGroup>
                </div>}
                {availableItemRender !== undefined && <div className={styles.list}>
                    {(items ?? []).length > 0 && (items ?? []).map(item => {
                        return <FormGroup
                            key={`available-user-${item[itemIdKey ?? ""]}`}
                            id={`${item[itemIdKey ?? ""]}-form-group`}
                            className={styles.selectOption}
                        >
                            <FormControlLabel
                                id={`${item[itemIdKey ?? ""]}-form-control-label`}
                                control={
                                    <Checkbox
                                        id={`${item[itemIdKey ?? ""]}-checkbox`}
                                        checked={selectedItems.find(_ => _[itemIdKey ?? ""] === item[itemIdKey ?? ""]) !== undefined}
                                        disabled={disabled}
                                        onChange={e => handleItemSelect(item, e.target.checked)}
                                    />
                                } label={availableItemRender(item)} />
                        </FormGroup>
                    })}
                </div>}
            </div>}
        </div>
    );
}
